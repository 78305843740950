import React from "react";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Search } from "lucide-react";

interface BookingListHeaderProps {
  sortOption: string;
  setSortOption: (option: string) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const BookingListHeader: React.FC<BookingListHeaderProps> = ({
  sortOption,
  setSortOption,
  searchTerm,
  setSearchTerm,
}) => {
  return (
    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 items-start sm:items-center mb-4">
      <div className="w-full sm:w-auto flex-grow relative">
        <Input
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-10 w-full"
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
      </div>
      <Select value={sortOption} onValueChange={setSortOption}>
        <SelectTrigger className="w-full sm:w-[180px]">
          <SelectValue placeholder="Trier par" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="startDate">Date de début</SelectItem>
          <SelectItem value="created">Date de création</SelectItem>
          <SelectItem value="updated">Dernière mise à jour</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};

export default BookingListHeader;
