import type { Room, Booking, DayData } from "../types";

class TimelineData {
  private bookings: Booking[];
  private rooms: Room[];

  constructor(bookings: Booking[], rooms: Room[]) {
    this.bookings = bookings;
    this.rooms = rooms;
  }

  generateTimeline(): DayData[] {
    const { startDate, endDate } = this.getTimelineRange(this.bookings);
    const waitingListPositions = this.assignWaitingListPositions(this.bookings);
    const maxWaitingListBookings = waitingListPositions.size;

    return this.generateTimelineData(
      startDate,
      endDate,
      this.bookings,
      waitingListPositions,
      maxWaitingListBookings,
    );
  }

  private getTimelineRange(activeBookings: Booking[]): {
    startDate: Date;
    endDate: Date;
  } {
    const earliestStartDate = new Date(
      Math.min(...activeBookings.map((b) => new Date(b.start_date).getTime())),
    );
    const latestEndDate = new Date(
      Math.max(...activeBookings.map((b) => new Date(b.end_date).getTime())),
    );

    const startDate = new Date(earliestStartDate);
    startDate.setDate(startDate.getDate() - 2);

    const endDate = new Date(
      Math.max(
        latestEndDate.getTime(),
        startDate.getTime() + 90 * 24 * 60 * 60 * 1000,
      ),
    );

    return { startDate, endDate };
  }

  private assignWaitingListPositions(
    activeBookings: Booking[],
  ): Map<string, number> {
    const waitingListBookings = activeBookings
      .filter((b) => b.room === "Liste d'attente")
      .sort(
        (a, b) =>
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
      );

    const waitingListPositions = new Map();
    waitingListBookings.forEach((booking, index) => {
      waitingListPositions.set(booking.id, index);
    });

    return waitingListPositions;
  }

  private generateTimelineData(
    startDate: Date,
    endDate: Date,
    activeBookings: Booking[],
    waitingListPositions: Map<string, number>,
    maxWaitingListBookings: number,
  ): DayData[] {
    const timeline: DayData[] = [];

    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      const dayBookings = this.getDayBookings(d, activeBookings);
      const waitingListSlots = this.getWaitingListSlots(
        d,
        activeBookings,
        waitingListPositions,
        maxWaitingListBookings,
      );
      const availableRooms = this.getAvailableRooms(dayBookings);

      timeline.push({
        date: new Date(d),
        roomBookings: dayBookings,
        waitingListSlots: waitingListSlots,
        availableRooms: availableRooms,
        maxWaitingListBookings: maxWaitingListBookings,
      });
    }

    return timeline;
  }

  private getDayBookings(date: Date, activeBookings: Booking[]) {
    return this.rooms.map((room) => ({
      ...room,
      booking:
        activeBookings.find(
          (b) =>
            b.room === room.name &&
            new Date(b.start_date) <= date &&
            new Date(b.end_date) > date,
        ) || null,
    }));
  }

  private getWaitingListSlots(
    date: Date,
    activeBookings: Booking[],
    waitingListPositions: Map<string, number>,
    maxWaitingListBookings: number,
  ) {
    const waitingList = activeBookings.filter(
      (b) =>
        b.room === "Liste d'attente" &&
        new Date(b.start_date) <= date &&
        new Date(b.end_date) > date,
    );

    const waitingListSlots = new Array(maxWaitingListBookings).fill(null);

    waitingList.forEach((booking) => {
      const position = waitingListPositions.get(booking.id);
      if (position !== undefined) {
        waitingListSlots[position] = booking;
      }
    });

    return waitingListSlots;
  }

  private getAvailableRooms(
    dayBookings: { name: string; booking: Booking | null }[],
  ) {
    return dayBookings.filter(
      (room) => !room.booking && room.name !== "Liste d'attente",
    ).length;
  }
}

export default TimelineData;
