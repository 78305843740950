import React from "react";
import { ChevronRight } from "lucide-react";

interface ScrollChevronProps {
  showChevron: boolean;
  onClick: () => void;
}

const ScrollChevron: React.FC<ScrollChevronProps> = ({
  showChevron,
  onClick,
}) => {
  if (!showChevron) return null;

  return (
    <div
      className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-75 p-2 rounded-full shadow-md cursor-pointer transition-opacity duration-300"
      onClick={onClick}
    >
      <ChevronRight className="h-4 w-4 text-gray-400" />
    </div>
  );
};

export default ScrollChevron;
