import React from "react";

const TimelineHeader: React.FC = () => {
  return (
    <div className="flex justify-between mb-2">
      <div className="flex items-center">
        <div className="w-4 h-4 bg-blue-400 rounded-full mr-2"></div>
        <span className="text-sm">Réservé</span>
      </div>
      <div className="flex items-center">
        <div className="w-4 h-4 bg-blue-200 rounded-full mr-2"></div>
        <span className="text-sm">Pas sûr</span>
      </div>
      <div className="flex items-center">
        <div className="w-4 h-4 bg-stripe-blue rounded-full mr-2"></div>
        <span className="text-sm">Liste d'attente</span>
      </div>
    </div>
  );
};

export default TimelineHeader;
