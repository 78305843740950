import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CalendarDays, Edit, Trash2 } from "lucide-react";
import type { Booking } from "../types";

interface BookingCardProps {
  booking: Booking;
  onEdit: (booking: Booking) => void;
  onDelete: (bookingId: string) => void;
  highlightedBookingId: string | null;
  onBookingClick: (bookingId: string) => void;
}

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString("fr-FR", options);
};

const generateColor = (name: string) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${hash % 360}, 70%, 80%)`;
};

const BookingCard: React.FC<BookingCardProps> = ({
  booking,
  onEdit,
  onDelete,
  highlightedBookingId,
  onBookingClick,
}) => {
  return (
    <Card
      key={booking.id}
      data-booking-id={booking.id}
      className={`mx-1 my-3 hover:shadow-md transition-shadow duration-300 ${
        booking.maybe ? "border-dashed" : ""
      } ${booking.room === "Liste d'attente" ? "bg-gray-50" : ""} ${
        booking.id === highlightedBookingId ? "ring-2 ring-blue-400" : ""
      }`}
      onClick={() => onBookingClick(booking.id)}
    >
      <CardContent className="p-3">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="flex items-center space-x-3 mb-2 sm:mb-0">
            <Avatar className="h-8 w-8">
              <AvatarFallback
                style={{ backgroundColor: generateColor(booking.guest_name) }}
              >
                {booking.guest_name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")
                  .toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <div>
              <p
                className={`font-medium ${booking.maybe ? "text-gray-500" : ""}`}
              >
                {booking.guest_name}
                {booking.comment && (
                  <span className="ml-2 italic text-sm text-gray-500">
                    {booking.comment}
                  </span>
                )}
              </p>
              <div className="flex items-center text-xs text-gray-500">
                <span>{booking.room}</span>
                <span className="mx-1">•</span>
                <CalendarDays className="h-3 w-3 mr-1" />
                <span>
                  {formatDate(booking.start_date)} -{" "}
                  {formatDate(booking.end_date)}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2 mt-2 sm:mt-0">
            {booking.maybe && booking.room !== "Liste d'attente" && (
              <Badge variant="secondary" className="text-xs">
                Pas sûr(e)
              </Badge>
            )}
            {booking.room === "Liste d'attente" && (
              <Badge variant="outline" className="text-xs">
                Attente
              </Badge>
            )}
            <Button variant="ghost" size="icon" onClick={() => onEdit(booking)}>
              <Edit className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => onDelete(booking.id)}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BookingCard;
