import "./App.css";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  createClient,
  SupabaseClient,
  RealtimeChannel,
} from "@supabase/supabase-js";
import BookingList from "./bookinglist/BookingList";
import Timeline from "./timeline/Timeline";
import BookingDialog from "./BookingDialog";
import RoomManagement from "room/RoomManagement";
import { Button } from "@/components/ui/button";
import { Calendar } from "lucide-react";
import type { Room, Booking } from "./types";
import { TooltipProvider } from "@/components/ui/tooltip";
import { showToast, Toaster } from "./toast";

const supabaseUrl = "https://wzcnyidrjyflpfmaxpjm.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Ind6Y255aWRyanlmbHBmbWF4cGptIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTkyNTU0MzAsImV4cCI6MjAzNDgzMTQzMH0.AZe1ALP10csFvNgMWg0usgzIZ5F9_phaIg_CoG" +
  (window.location.hash.slice(1) || "");

const supabase: SupabaseClient = createClient(supabaseUrl, supabaseKey);

const RoomSharingApp: React.FC = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingBookingId, setEditingBookingId] = useState<string | null>(null);
  const [highlightedBookingId, setHighlightedBookingId] = useState<
    string | null
  >(null);

  const fetchRooms = useCallback(async () => {
    const { data, error } = await supabase
      .from("rooms")
      .select("*")
      .order("id");
    if (error) {
      console.error("Error fetching rooms:", error);
      showToast("Erreur lors de la récupération des chambres.", "error");
    } else {
      setRooms(data as Room[]);
    }
  }, []);

  const fetchBookings = useCallback(async () => {
    const { data, error } = await supabase.from("bookings").select("*");
    if (error) {
      console.error("Error fetching bookings:", error);
      showToast("Erreur lors de la récupération des réservations.", "error");
    } else {
      setBookings(data as Booking[]);
    }
  }, []);

  useEffect(() => {
    fetchRooms();
    fetchBookings();

    const roomsChannel: RealtimeChannel = supabase
      .channel("rooms_changes")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "rooms" },
        fetchRooms,
      )
      .subscribe();

    const bookingsChannel: RealtimeChannel = supabase
      .channel("bookings_changes")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "bookings" },
        fetchBookings,
      )
      .subscribe();

    return () => {
      supabase.removeChannel(roomsChannel);
      supabase.removeChannel(bookingsChannel);
    };
  }, [fetchBookings, fetchRooms]);

  const handleEditBooking = useCallback((booking: Booking) => {
    setEditingBookingId(booking.id);
    setIsDialogOpen(true);
  }, []);

  const handleDeleteBooking = useCallback(async (bookingId: string) => {
    const { error } = await supabase
      .from("bookings")
      .delete()
      .eq("id", bookingId);
    if (error) {
      console.error("Error removing booking:", error);
      showToast("Erreur lors de la suppression de la demande.", "error");
    } else {
      showToast("Demande annulée, pas de souci ! 👍", "success");
    }
  }, []);

  const handleBookingClick = useCallback((bookingId: string | null) => {
    setHighlightedBookingId(bookingId);
  }, []);

  const sortedBookings = useMemo(
    () =>
      [...bookings].sort(
        (a, b) =>
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
      ),
    [bookings],
  );

  const handleSaveBooking = useCallback((bookingId: string) => {
    setHighlightedBookingId(bookingId);
  }, []);

  if (!window.location.hash || window.location.hash.length !== 6) {
    return <div>This website is private.</div>;
  }

  return (
    <TooltipProvider>
      <div className="p-4 max-w-4xl mx-auto">
        <Toaster position="top-right" />
        <h1 className="text-2xl font-bold mb-4">Grisy - Partage de chambres</h1>

        <Button
          onClick={() => {
            setEditingBookingId(null);
            setIsDialogOpen(true);
          }}
        >
          <Calendar className="mr-2 h-4 w-4" /> Demander une chambre
        </Button>

        <Timeline
          bookings={bookings}
          rooms={rooms}
          onBookingClick={handleBookingClick}
          supabase={supabase}
          highlightedBookingId={highlightedBookingId}
        />

        <BookingDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSave={handleSaveBooking}
          editingBookingId={editingBookingId}
          rooms={rooms}
          bookings={bookings}
          supabase={supabase}
        />

        <BookingList
          bookings={sortedBookings}
          onEdit={handleEditBooking}
          onDelete={handleDeleteBooking}
          highlightedBookingId={highlightedBookingId}
          onBookingClick={handleBookingClick}
        />

        <RoomManagement
          supabase={supabase}
          rooms={rooms}
          onRoomsChange={fetchRooms}
        />
      </div>
    </TooltipProvider>
  );
};

export default RoomSharingApp;
