import toast, { Toaster } from "react-hot-toast";

export const showToast = (message: string, type: "success" | "error") => {
  toast[type](message, {
    style: {
      borderRadius: "10px",
      background: type === "success" ? "#10B981" : "#EF4444",
      color: "#fff",
    },
  });
};

export { Toaster };
