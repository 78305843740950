import React, { useState, useEffect, useMemo } from "react";
import { SupabaseClient } from "@supabase/supabase-js";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { DateRange, Range, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import type { Booking, BookingEgg, Room } from "./types";
import { fr } from "date-fns/locale";
import { showToast } from "./toast";

interface BookingDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (bookingId: string) => void;
  editingBookingId: string | null;
  rooms: Room[];
  bookings: Booking[];
  supabase: SupabaseClient;
  prefilledData?: {
    start_date: string;
    end_date: string;
    room: string;
  };
}

const initialBookingState: BookingEgg = {
  guest_name: "",
  comment: "",
  room: "",
  start_date: "",
  end_date: "",
  maybe: false,
};

function dateToYYYYMMDD(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const BookingDialog: React.FC<BookingDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  editingBookingId,
  rooms,
  bookings,
  supabase,
  prefilledData,
}) => {
  const [newBooking, setNewBooking] = useState<BookingEgg>(initialBookingState);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  useEffect(() => {
    if (editingBookingId) {
      const bookingToEdit = bookings.find((b) => b.id === editingBookingId);
      if (bookingToEdit) {
        setNewBooking(bookingToEdit);
        setDateRange({
          startDate: new Date(bookingToEdit.start_date),
          endDate: new Date(bookingToEdit.end_date),
          key: "selection",
        });
      }
    } else if (prefilledData) {
      setNewBooking({
        ...initialBookingState,
        ...prefilledData,
      });
      setDateRange({
        startDate: new Date(prefilledData.start_date),
        endDate: new Date(prefilledData.end_date),
        key: "selection",
      });
    } else {
      setNewBooking(initialBookingState);
      setDateRange({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      });
    }
  }, [editingBookingId, bookings, prefilledData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewBooking((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoomChange = (value: string) => {
    setNewBooking((prev) => ({
      ...prev,
      room: value,
      maybe: value === "Liste d'attente" ? true : prev.maybe,
    }));
  };

  const handleMaybeChange = (checked: boolean) => {
    setNewBooking((prev) => ({ ...prev, maybe: checked }));
  };

  const handleDateRangeChange = (ranges: RangeKeyDict) => {
    const selection = ranges.selection;
    if (selection.startDate && selection.endDate) {
      setDateRange(selection);
      setNewBooking((prev) => ({
        ...prev,
        start_date: dateToYYYYMMDD(selection.startDate!),
        end_date: dateToYYYYMMDD(selection.endDate!),
      }));
    }
  };

  const availableRooms = React.useMemo(() => {
    if (!newBooking.start_date || !newBooking.end_date) return rooms;
    const unavailableRooms = bookings
      .filter(
        (booking) =>
          booking.id !== editingBookingId &&
          new Date(booking.start_date) < new Date(newBooking.end_date) &&
          new Date(booking.end_date) > new Date(newBooking.start_date),
      )
      .map((booking) => booking.room);
    return [
      ...rooms.filter((room) => !unavailableRooms.includes(room.name)),
      { id: rooms.length + 1, name: "Liste d'attente" },
    ];
  }, [
    newBooking.start_date,
    newBooking.end_date,
    bookings,
    editingBookingId,
    rooms,
  ]);

  const conflictingBookings = useMemo(() => {
    if (!newBooking.start_date || !newBooking.end_date) return [];
    return bookings
      .filter(
        (booking) =>
          booking.id !== editingBookingId &&
          booking.room !== "Liste d'attente" &&
          new Date(booking.start_date) < new Date(newBooking.end_date) &&
          new Date(booking.end_date) > new Date(newBooking.start_date),
      )
      .sort((a, b) => a.room.localeCompare(b.room));
  }, [newBooking.start_date, newBooking.end_date, bookings, editingBookingId]);

  const handleBookRoom = async () => {
    if (
      newBooking.guest_name &&
      newBooking.room &&
      newBooking.start_date &&
      newBooking.end_date
    ) {
      const bookingData = {
        guest_name: newBooking.guest_name,
        comment: newBooking.comment,
        room: newBooking.room,
        start_date: newBooking.start_date,
        end_date: newBooking.end_date,
        maybe: newBooking.room === "Liste d'attente" ? true : newBooking.maybe,
      };

      let result;
      if (editingBookingId) {
        result = await supabase
          .from("bookings")
          .update(bookingData)
          .eq("id", editingBookingId);
      } else {
        result = await supabase.from("bookings").insert([bookingData]).select();
      }

      if (result.error) {
        console.error("Error handling booking:", result.error);
        showToast("Erreur lors de la gestion de la demande.", "error");
      } else {
        showToast(
          editingBookingId
            ? "Cool, ta demande a été mise à jour ! 🎉"
            : "Super, ta chambre est notée ! 🎉",
          "success",
        );
        onSave(editingBookingId || result.data![0].id);
        onClose();
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] flex flex-col max-h-[90vh]">
        <DialogHeader className="flex-shrink-0">
          <DialogTitle>
            {editingBookingId ? "Modifier ta demande" : "Demander une chambre"}
          </DialogTitle>
          <DialogDescription>
            Remplis les détails pour{" "}
            {editingBookingId ? "modifier ta" : "faire ta"} demande.
          </DialogDescription>
        </DialogHeader>
        <div className="flex-grow overflow-y-auto">
          <div className="grid gap-4 py-4 px-2">
            <Input
              name="guest_name"
              placeholder="Ton nom"
              value={newBooking.guest_name}
              onChange={handleInputChange}
            />
            <Input
              name="comment"
              placeholder="Commentaire (optionnel)"
              value={newBooking.comment || ""}
              onChange={handleInputChange}
            />
            <DateRange
              ranges={[dateRange]}
              onChange={handleDateRangeChange}
              minDate={new Date()}
              locale={fr}
              showDateDisplay={false}
            />
            {newBooking.start_date && newBooking.end_date && (
              <div className="text-sm text-gray-500">
                {availableRooms.length - 1} chambre
                {availableRooms.length - 1 > 1 ? "s" : ""} dispo
                {availableRooms.length - 1 > 1 ? "s" : ""}
              </div>
            )}
            <Select onValueChange={handleRoomChange} value={newBooking.room}>
              <SelectTrigger>
                <SelectValue placeholder="Choisis ta chambre" />
              </SelectTrigger>
              <SelectContent>
                {availableRooms.map((room) => (
                  <SelectItem key={room.id} value={room.name}>
                    {room.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {newBooking.room !== "Liste d'attente" && (
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="maybe"
                  checked={newBooking.maybe}
                  onCheckedChange={handleMaybeChange}
                />
                <label
                  htmlFor="maybe"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Pas sûr(e) encore ?
                </label>
              </div>
            )}
            {conflictingBookings.length > 0 && (
              <Alert>
                <AlertTitle>Chambres déjà demandées :</AlertTitle>
                <AlertDescription>
                  {conflictingBookings.map((booking) => (
                    <div
                      key={booking.id}
                      className={booking.maybe ? "text-gray-500" : ""}
                    >
                      <strong>{booking.room}</strong> : {booking.guest_name} (
                      {new Date(booking.start_date).toLocaleDateString("fr-FR")}{" "}
                      - {new Date(booking.end_date).toLocaleDateString("fr-FR")}
                      ){booking.maybe && " (Pas sûr(e))"}
                    </div>
                  ))}
                </AlertDescription>
              </Alert>
            )}
          </div>
        </div>
        <DialogFooter className="flex-shrink-0">
          <Button
            onClick={handleBookRoom}
            disabled={
              !newBooking.guest_name ||
              !newBooking.room ||
              !newBooking.start_date ||
              !newBooking.end_date
            }
          >
            {editingBookingId ? "Modifier ma demande" : "Demander la chambre"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BookingDialog;
