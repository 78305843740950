import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { SupabaseClient } from "@supabase/supabase-js";
import { Room } from "../types";
import RoomCard from "./RoomCard";
import RoomEditDialog from "./RoomEditDialog";
import { showToast } from "../toast";

interface RoomManagementProps {
  supabase: SupabaseClient;
  rooms: Room[];
  onRoomsChange: () => void;
}

const RoomManagement: React.FC<RoomManagementProps> = ({
  supabase,
  rooms,
  onRoomsChange,
}) => {
  const [editingRoom, setEditingRoom] = useState<
    Omit<Room, "id"> | Room | null
  >(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleEditRoom = (room: Room) => {
    setEditingRoom({ ...room });
    setIsEditDialogOpen(true);
  };

  const handleAddRoom = () => {
    setEditingRoom({ name: "" });
    setIsEditDialogOpen(true);
  };

  const handleSaveRoom = async (savedRoom: Omit<Room, "id"> | Room) => {
    if ("id" in savedRoom) {
      const { error } = await supabase
        .from("rooms")
        .update(savedRoom)
        .eq("id", savedRoom.id);
      if (error) {
        console.error("Error updating room:", error);
        showToast("Erreur lors de la mise à jour de la chambre.", "error");
      } else {
        showToast("Chambre mise à jour avec succès.", "success");
      }
    } else {
      const { error } = await supabase.from("rooms").insert([savedRoom]);
      if (error) {
        console.error("Error adding room:", error);
        showToast("Erreur lors de l'ajout de la chambre.", "error");
      } else {
        showToast("Chambre ajoutée avec succès.", "success");
      }
    }
    onRoomsChange();
    setIsEditDialogOpen(false);
    setEditingRoom(null);
  };

  const handleDeleteRoom = async (roomId: number) => {
    const { error } = await supabase.from("rooms").delete().eq("id", roomId);
    if (error) {
      console.error("Error deleting room:", error);
      showToast("Erreur lors de la suppression de la chambre.", "error");
    } else {
      showToast("Chambre supprimée avec succès.", "success");
      onRoomsChange();
    }
  };

  return (
    <div className="mt-8 bg-gray-100 p-4 rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-800">
          Gestion des chambres
        </h2>
        <Button onClick={handleAddRoom} size="sm">
          <Plus className="h-4 w-4 mr-2" />
          Ajouter une chambre
        </Button>
      </div>
      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {rooms.map((room) => (
          <RoomCard key={room.id} room={room} onEdit={handleEditRoom} />
        ))}
      </div>
      <RoomEditDialog
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        room={editingRoom}
        onSave={handleSaveRoom}
        onDelete={handleDeleteRoom}
        supabase={supabase}
      />
    </div>
  );
};

export default RoomManagement;
