import React, { useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Edit, Camera } from "lucide-react";
import { Room } from "../types";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface RoomCardProps {
  room: Room;
  onEdit: (room: Room) => void;
}

const DEFAULT_IMAGE_URL = "/room.png";

const RoomCard: React.FC<RoomCardProps> = ({ room, onEdit }) => {
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);

  const handleImageClick = (imageUrl: string) => {
    if (imageUrl !== DEFAULT_IMAGE_URL) {
      setEnlargedImage(imageUrl);
    }
  };

  return (
    <>
      <Card className="overflow-hidden hover:shadow-md transition-shadow duration-300">
        <CardContent className="p-2">
          <div className="flex items-start">
            <div
              className={`w-16 h-16 mr-3 relative rounded-lg overflow-hidden shadow-sm flex-shrink-0 ${
                room.imageUrl && room.imageUrl !== DEFAULT_IMAGE_URL
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={() =>
                room.imageUrl &&
                room.imageUrl !== DEFAULT_IMAGE_URL &&
                handleImageClick(room.imageUrl)
              }
            >
              <img
                src={room.imageUrl || DEFAULT_IMAGE_URL}
                alt={room.name}
                className="w-full h-full object-cover"
              />
              {room.imageUrl && room.imageUrl !== DEFAULT_IMAGE_URL && (
                <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-200">
                  <Camera className="text-white" size={16} />
                </div>
              )}
            </div>
            <div className="flex-grow min-w-0">
              <h3 className="font-semibold text-base text-gray-800 break-words">
                {room.name}
              </h3>
              <Tooltip>
                <TooltipTrigger asChild>
                  <p className="text-xs text-gray-600 break-words">
                    {room.description || "Pas de description"}
                  </p>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{room.description || "Pas de description"}</p>
                </TooltipContent>
              </Tooltip>
            </div>
            <div className="ml-2 flex-shrink-0">
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={() => onEdit(room)}
              >
                <Edit className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <Dialog
        open={!!enlargedImage}
        onOpenChange={() => setEnlargedImage(null)}
      >
        <DialogContent className="sm:max-w-[600px] p-0 overflow-hidden rounded-lg">
          <div className="relative">
            <img
              src={enlargedImage!}
              alt="Chambre agrandie"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RoomCard;
