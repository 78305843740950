import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import type { DayData } from "./types";

interface DayDetailsDialogProps {
  selectedDay: Date | null;
  setSelectedDay: (day: Date | null) => void;
  timelineData: DayData[];
}

const DayDetailsDialog: React.FC<DayDetailsDialogProps> = ({
  selectedDay,
  setSelectedDay,
  timelineData,
}) => {
  const dayData = selectedDay
    ? timelineData.find((d) => d.date.getTime() === selectedDay.getTime())
    : null;

  return (
    <Dialog open={!!selectedDay} onOpenChange={() => setSelectedDay(null)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Détails pour le{" "}
            {selectedDay?.toLocaleDateString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </DialogTitle>
        </DialogHeader>
        {dayData && (
          <>
            <div className="space-y-2">
              {dayData.roomBookings.map((room, idx) => (
                <div key={idx} className="flex justify-between items-center">
                  <span className="font-semibold">{room.name}:</span>
                  {room.booking ? (
                    <span>
                      {room.booking.guest_name}
                      {room.booking.maybe && (
                        <span className="text-gray-500 ml-1">(Pas sûr(e))</span>
                      )}
                    </span>
                  ) : (
                    <span className="text-green-500">Libre</span>
                  )}
                </div>
              ))}
            </div>
            {dayData.waitingListSlots.filter((b) => b).length > 0 && (
              <div className="mt-4">
                <h4 className="font-semibold mb-2">Liste d'attente :</h4>
                <ul className="list-disc list-inside space-y-1">
                  {dayData.waitingListSlots
                    .filter((b) => b !== null)
                    .map((booking, idx) => (
                      <li key={idx}>
                        {booking.guest_name}
                        {booking.maybe && (
                          <span className="text-gray-500 ml-1">
                            (Pas sûr(e))
                          </span>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DayDetailsDialog;
