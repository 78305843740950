import React, { useState } from "react";
import { Plus } from "lucide-react";
import type { Booking, RoomBooking } from "../types";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface TimelineCellProps {
  roomBooking: RoomBooking;
  date: Date;
  onBookingClick: (bookingId: string | null) => void;
  onQuickBookingClick: (date: Date, room: string) => void;
  highlightedBookingId: string | null;
  isWaitingList?: boolean;
  isToday: boolean;
}

const TimelineCell: React.FC<TimelineCellProps> = ({
  roomBooking,
  date,
  onBookingClick,
  onQuickBookingClick,
  highlightedBookingId,
  isWaitingList = false,
  isToday,
}) => {
  const [quickBookingSlot, setQuickBookingSlot] = useState<{
    date: Date;
    room: string;
  } | null>(null);

  const handleCellClick = () => {
    if (roomBooking.booking) {
      onBookingClick(roomBooking.booking.id);
    } else {
      if (
        quickBookingSlot &&
        quickBookingSlot.date.getTime() === date.getTime() &&
        quickBookingSlot.room === roomBooking.name
      ) {
        onQuickBookingClick(date, roomBooking.name);
        setQuickBookingSlot(null);
      } else {
        setQuickBookingSlot({ date, room: roomBooking.name });
      }
    }
  };

  const calculateBookingWidth = (startDate: Date, endDate: Date) => {
    const daysDiff = Math.round(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
    );
    return Math.max(daysDiff, 1) * 56; // 56px is the width of each day cell, ensure minimum width of 56px
  };

  const calculateBookingOffset = (startDate: Date, currentDate: Date) => {
    const diffDays = Math.floor(
      (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
    );
    return Math.max(diffDays, 0) * 56; // 56px is the width of each day cell
  };

  const renderBooking = (booking: Booking) => {
    const startDate = new Date(booking.start_date);
    const endDate = new Date(booking.end_date);

    if (date < startDate || date > endDate) {
      return null;
    }

    const width = calculateBookingWidth(startDate, endDate);
    const offset = calculateBookingOffset(startDate, date);

    return (
      <Tooltip key={booking.id}>
        <TooltipTrigger asChild>
          <div>
            <div
              className="absolute top-0 left-0 h-full"
              style={{ left: `-${offset}px` }}
            >
              <div
                className={`absolute top-0 left-0 h-full flex flex-col justify-center px-1 cursor-pointer rounded-md text-xs text-white font-semibold
                            ${booking.maybe ? "bg-blue-200" : "bg-blue-400"}
                            ${isWaitingList ? "bg-stripe-blue" : ""}
                            ${booking.id === highlightedBookingId ? "ring-2 ring-blue-600" : ""}
                            ${isToday ? "ring-1 ring-yellow-400" : ""}
                        `}
                style={{
                  width: `${width}px`,
                }}
                onClick={() => onBookingClick(booking.id)}
              >
                <span
                  className={booking.comment ? "line-clamp-1" : "line-clamp-2"}
                >
                  {booking.guest_name}
                </span>
                {booking.comment && (
                  <span className="line-clamp-1 text-[10px] italic text-white/80">
                    {booking.comment}
                  </span>
                )}
              </div>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>
            <strong>{booking.guest_name}</strong>
            {booking.comment && (
              <span className="ml-2 italic">{booking.comment}</span>
            )}
          </p>
          <p>{booking.room}</p>
          <p>
            {new Date(booking.start_date).toLocaleDateString("fr-FR")} -{" "}
            {new Date(booking.end_date).toLocaleDateString("fr-FR")}
          </p>
          {booking.maybe && <p>Pas sûr(e)</p>}
          {isWaitingList && <p>Liste d'attente</p>}
        </TooltipContent>
      </Tooltip>
    );
  };

  return (
    <div className={`h-10 relative border-b`}>
      {isToday ? (
        <div className="bg-yellow-50 w-full h-full absolute -ml-[50%] -z-10"></div>
      ) : null}
      {roomBooking.booking ? (
        renderBooking(roomBooking.booking)
      ) : (
        <div
          className={`absolute top-0 left-0 w-full h-full cursor-pointer flex items-center justify-center group`}
          onClick={handleCellClick}
        >
          <Plus
            className={`h-4 w-4 text-blue-500 opacity-0 group-hover:opacity-60 transition-opacity duration-200
                        ${
                          quickBookingSlot &&
                          quickBookingSlot.date.getTime() === date.getTime() &&
                          quickBookingSlot.room === roomBooking.name
                            ? "opacity-100 group-hover:opacity-100"
                            : ""
                        }`}
          />
        </div>
      )}
    </div>
  );
};

export default TimelineCell;
