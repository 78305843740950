import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import type { DayData } from "../types";

interface TimelineTooltipProps {
  dayData: DayData;
  setSelectedDay: (day: Date) => void;
  isToday: boolean;
}

const AvailableRoomsTooltip: React.FC<TimelineTooltipProps> = ({
  dayData,
  setSelectedDay,
  isToday,
}) => {
  return (
    <div
      className={`h-20 text-center border-b flex flex-col justify-end pb-1 -ml-[50%] w-full ${isToday ? "bg-yellow-50" : ""}`}
    >
      <div
        className={`text-xs font-semibold ${isToday ? "text-yellow-700" : ""}`}
      >
        {dayData.date.toLocaleDateString("fr-FR", { weekday: "short" })}
      </div>
      <div className={`text-xs ${isToday ? "font-bold" : ""}`}>
        {dayData.date.getDate()}
      </div>
      {[1, 10, 20].includes(dayData.date.getDate()) && (
        <div className="text-xs -mb-1">
          {dayData.date.toLocaleDateString("fr-FR", { month: "short" })}
        </div>
      )}
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className={`mx-1 rounded-lg flex items-center justify-center text-white font-bold cursor-pointer ${
              dayData.availableRooms === 0
                ? "bg-red-500"
                : dayData.availableRooms < 3
                  ? "bg-yellow-500"
                  : "bg-green-500"
            } ${isToday ? "ring-1 ring-yellow-400" : ""}`}
            style={{
              height: [1, 10, 20].includes(dayData.date.getDate())
                ? "2rem"
                : "2.5rem",
              marginTop: [1, 10, 20].includes(dayData.date.getDate())
                ? "0.25rem"
                : "0",
            }}
            onClick={() => setSelectedDay(dayData.date)}
          >
            {dayData.availableRooms}
          </div>
        </TooltipTrigger>
        <TooltipContent side="bottom" align="center">
          <p className="font-semibold mb-1 z-10">Occupation des chambres :</p>
          {dayData.roomBookings.map((room, idx) => (
            <div key={idx}>
              {room.booking ? (
                <p>
                  {room.name} : {room.booking.guest_name}
                  {room.booking.comment && (
                    <span className="ml-2 italic">{room.booking.comment}</span>
                  )}
                  {room.booking.maybe && " (Pas sûr(e))"}
                </p>
              ) : (
                <p>{room.name} : Libre</p>
              )}
            </div>
          ))}
          {dayData.waitingListSlots.filter((b) => b).length > 0 && (
            <div className="mt-2">
              <p className="font-semibold">Liste d'attente :</p>
              <ul className="list-disc list-inside">
                {dayData.waitingListSlots
                  .filter((b) => b !== null)
                  .map((booking, idx) => (
                    <li key={idx} className="text-sm">
                      {booking.guest_name}
                      {booking.comment && (
                        <span className="ml-2 italic">{booking.comment}</span>
                      )}
                      {booking.maybe && " (Pas sûr(e))"}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

export default AvailableRoomsTooltip;
