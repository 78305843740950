import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import type { Room, DayData } from "../types";

interface TimelineContainerProps {
  containerRef: React.RefObject<HTMLDivElement>;
  rooms: Room[];
  timelineData: DayData[];
  children: React.ReactNode;
}

const TimelineContainer: React.FC<TimelineContainerProps> = ({
  containerRef,
  rooms,
  timelineData,
  children,
}) => {
  return (
    <div className="relative flex" ref={containerRef}>
      <div className="sticky left-0 z-20 bg-white">
        <div className="h-20 flex items-end justify-center text-sm pb-2 border-b">
          Dispo
        </div>
        {rooms.map((room) => (
          <div
            key={room.id}
            className="h-10 w-14 flex items-center justify-end text-xs leading-tight border-b"
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="text-center line-clamp-2">{room.name}</div>
              </TooltipTrigger>
              <TooltipContent>
                <p>{room.name}</p>
              </TooltipContent>
            </Tooltip>
          </div>
        ))}
        {Array.from({
          length: timelineData[0]?.maxWaitingListBookings,
        }).map((_, index) => (
          <div
            key={`waiting-list-${index}`}
            className="h-10 w-14 flex items-center justify-end text-xs leading-tight border-b"
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="w-18 text-center line-clamp-2">
                  {index === 0 ? "Liste d'attente" : ""}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Liste d'attente</p>
              </TooltipContent>
            </Tooltip>
          </div>
        ))}
      </div>
      <div className="overflow-hidden">{children}</div>
    </div>
  );
};

export default TimelineContainer;
