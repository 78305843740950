import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { SupabaseClient } from "@supabase/supabase-js";
import { Room } from "../types";
import RoomEditForm from "./RoomEditForm";

interface RoomEditDialogProps {
  isOpen: boolean;
  onClose: () => void;
  room: Omit<Room, "id"> | Room | null;
  onSave: (room: Omit<Room, "id"> | Room) => void;
  onDelete: (roomId: number) => void;
  supabase: SupabaseClient;
}

const RoomEditDialog: React.FC<RoomEditDialogProps> = ({
  isOpen,
  onClose,
  room,
  onSave,
  onDelete,
  supabase,
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDelete = () => {
    if (room && "id" in room) {
      onDelete(room.id);
      setIsDeleteDialogOpen(false);
      onClose();
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {room && "id" in room
                ? "Modifier la chambre"
                : "Ajouter une chambre"}
            </DialogTitle>
          </DialogHeader>
          {room && (
            <RoomEditForm
              room={room}
              onSave={onSave}
              onDelete={() => setIsDeleteDialogOpen(true)}
              supabase={supabase}
            />
          )}
        </DialogContent>
      </Dialog>

      <AlertDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirmer la suppression</AlertDialogTitle>
            <AlertDialogDescription>
              Êtes-vous sûr de vouloir supprimer cette chambre ? Cette action
              est irréversible.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Annuler</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>
              Supprimer
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default RoomEditDialog;
