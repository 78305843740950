import React, { useMemo, useState, useRef, useEffect } from "react";
import { FixedSizeList as List, ListOnItemsRenderedProps } from "react-window";
import { SupabaseClient } from "@supabase/supabase-js";
import type { Room, Booking } from "../types";
import TimelineHeader from "./TimelineHeader";
import TimelineRow from "./TimelineRow";
import DayDetailsDialog from "../DayDetailsDialog";
import BookingDialog from "../BookingDialog";
import TimelineData from "./TimelineData";
import TimelineContainer from "./TimelineContainer";
import ScrollChevron from "./ScrollChevron";

interface TimelineProps {
  bookings: Booking[];
  rooms: Room[];
  onBookingClick: (bookingId: string | null) => void;
  supabase: SupabaseClient;
  highlightedBookingId: string | null;
}

const Timeline: React.FC<TimelineProps> = ({
  bookings,
  rooms,
  onBookingClick,
  supabase,
  highlightedBookingId,
}) => {
  const [showChevron, setShowChevron] = useState(true);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [isBookingDialogOpen, setIsBookingDialogOpen] = useState(false);
  const [quickBookingData, setQuickBookingData] = useState<{
    date: Date;
    room: string;
  } | null>(null);
  const [editingBookingId, setEditingBookingId] = useState<string | null>(null);
  const listRef = useRef<List>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);

  const timelineData = useMemo(
    () => new TimelineData(bookings, rooms).generateTimeline(),
    [bookings, rooms],
  );

  const todayIndex = useMemo(() => {
    return timelineData.findIndex(
      (day) => day.date.toDateString() === new Date().toDateString(),
    );
  }, [timelineData]);

  useEffect(() => {
    if (listRef.current && todayIndex !== -1) {
      listRef.current.scrollTo(todayIndex * 56 - 30); // 56px is the width of each day cell
    }
  }, [todayIndex]);

  useEffect(() => {
    if (highlightedBookingId && listRef.current) {
      const highlightedBooking = bookings.find(
        (b) => b.id === highlightedBookingId,
      );
      if (highlightedBooking) {
        const startDate = new Date(highlightedBooking.start_date);
        const timelineStartDate = timelineData[0].date;
        const daysDiff = Math.floor(
          (startDate.getTime() - timelineStartDate.getTime()) /
            (1000 * 60 * 60 * 24),
        );
        listRef.current.scrollTo(daysDiff * 56 - 30); // 56px is the width of each day cell
      }
    }
  }, [highlightedBookingId, bookings, timelineData]);

  const handleItemsRendered = (listProps: ListOnItemsRenderedProps) => {
    if (containerRef.current) {
      setShowChevron(listProps.visibleStopIndex < timelineData.length - 1);
    }
  };

  const scrollTimeline = () => {
    if (scrollableRef.current) {
      const { clientWidth } = scrollableRef.current;
      scrollableRef.current?.scrollBy({
        left: clientWidth - 90,
        behavior: "smooth",
      });
    }
  };

  const handleQuickBookingClick = (date: Date, room: string) => {
    setQuickBookingData({ date, room });
    setIsBookingDialogOpen(true);
  };

  const handleCloseBookingDialog = () => {
    setIsBookingDialogOpen(false);
    setQuickBookingData(null);
    setEditingBookingId(null);
  };

  const handleSaveBooking = (bookingId: string) => {
    onBookingClick(bookingId);
    handleCloseBookingDialog();
  };

  const handleBookingClick = (bookingId: string | null) => {
    if (bookingId === highlightedBookingId) {
      setEditingBookingId(bookingId);
      setIsBookingDialogOpen(true);
    } else {
      onBookingClick(bookingId);
    }
  };

  return (
    <div className="mt-8">
      <h2 className="text-xl font-semibold mb-4">Disponibilité des chambres</h2>
      <div className="bg-gray-100 p-4 rounded-lg">
        <TimelineHeader />
        <TimelineContainer
          containerRef={containerRef}
          rooms={rooms}
          timelineData={timelineData}
        >
          <List
            ref={listRef}
            height={"100%"}
            itemCount={timelineData.length}
            itemSize={56}
            width={
              containerRef.current
                ? containerRef.current.offsetWidth - 72
                : window.innerWidth - 104
            }
            layout="horizontal"
            onItemsRendered={handleItemsRendered}
            outerRef={scrollableRef}
          >
            {({ index, style }) => (
              <TimelineRow
                style={style}
                dayData={timelineData[index]}
                setSelectedDay={setSelectedDay}
                onBookingClick={handleBookingClick}
                onQuickBookingClick={handleQuickBookingClick}
                highlightedBookingId={highlightedBookingId}
                isToday={index === todayIndex}
              />
            )}
          </List>
          <ScrollChevron showChevron={showChevron} onClick={scrollTimeline} />
        </TimelineContainer>
      </div>
      <DayDetailsDialog
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        timelineData={timelineData}
      />
      <BookingDialog
        isOpen={isBookingDialogOpen}
        onClose={handleCloseBookingDialog}
        onSave={handleSaveBooking}
        editingBookingId={editingBookingId}
        rooms={rooms}
        bookings={bookings}
        supabase={supabase}
        prefilledData={
          quickBookingData
            ? {
                start_date: quickBookingData.date.toISOString().split("T")[0],
                end_date: new Date(
                  quickBookingData.date.getTime() + 24 * 60 * 60 * 1000,
                )
                  .toISOString()
                  .split("T")[0],
                room: quickBookingData.room,
              }
            : undefined
        }
      />
    </div>
  );
};

export default Timeline;
