import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { DialogFooter } from "@/components/ui/dialog";
import { Save, Trash2, X } from "lucide-react";
import { SupabaseClient } from "@supabase/supabase-js";
import { Room } from "../types";

interface RoomEditFormProps {
  room: Omit<Room, "id"> | Room;
  onSave: (room: Omit<Room, "id"> | Room) => void;
  onDelete: () => void;
  supabase: SupabaseClient;
}

const RoomEditForm: React.FC<RoomEditFormProps> = ({
  room,
  onSave,
  onDelete,
  supabase,
}) => {
  const [editingRoom, setEditingRoom] = useState<Omit<Room, "id"> | Room>(room);
  const [isUploading, setIsUploading] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setEditingRoom({ ...editingRoom, [name]: value });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setIsUploading(true);
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;

      // Delete the old image if it exists
      if (editingRoom.imageUrl && editingRoom.imageUrl !== "/room.png") {
        const oldFileName = editingRoom.imageUrl.split("/").pop()!;
        await supabase.storage.from("room-images").remove([oldFileName]);
      }

      const { error: uploadError, data } = await supabase.storage
        .from("room-images")
        .upload(fileName, file);

      if (uploadError) {
        console.error("Error uploading file:", uploadError);
        setIsUploading(false);
        return;
      }

      if (data) {
        const {
          data: { publicUrl },
        } = supabase.storage.from("room-images").getPublicUrl(data.path);

        setEditingRoom({ ...editingRoom, imageUrl: publicUrl });
      }
      setIsUploading(false);
    }
  };

  const handleRemoveImage = async () => {
    if (editingRoom.imageUrl && editingRoom.imageUrl !== "/room.png") {
      const fileName = editingRoom.imageUrl.split("/").pop()!;
      const { error } = await supabase.storage
        .from("room-images")
        .remove([fileName]);

      if (error) {
        console.error("Error removing image:", error);
        return;
      }

      setEditingRoom({ ...editingRoom, imageUrl: "/room.png" });
    }
  };

  const handleSave = () => {
    onSave(editingRoom);
  };

  return (
    <div className="space-y-4">
      <Input
        name="name"
        value={editingRoom.name}
        onChange={handleInputChange}
        placeholder="Nom de la chambre"
        required
      />
      <Textarea
        name="description"
        value={editingRoom.description || ""}
        onChange={handleInputChange}
        placeholder="Description de la chambre (optionnel)"
      />
      <div>
        <label
          htmlFor="image-upload"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Image de la chambre (optionnel)
        </label>
        <div className="flex items-center space-x-2">
          <Input
            id="image-upload"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
          {editingRoom.imageUrl && editingRoom.imageUrl !== "/room.png" && (
            <Button
              variant="outline"
              size="icon"
              onClick={handleRemoveImage}
              title="Supprimer l'image"
            >
              <X className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
      {editingRoom.imageUrl && editingRoom.imageUrl !== "/room.png" && (
        <div className="mt-4 flex justify-center">
          <div className="relative w-40 h-40 rounded-lg overflow-hidden">
            <img
              src={editingRoom.imageUrl}
              alt="Preview"
              className="absolute top-0 left-0 h-32 w-full object-cover"
            />
          </div>
        </div>
      )}
      <DialogFooter className="flex justify-between">
        {"id" in editingRoom && (
          <Button variant="destructive" onClick={onDelete}>
            <Trash2 className="h-4 w-4 mr-2" />
            Supprimer
          </Button>
        )}
        <Button onClick={handleSave} disabled={isUploading}>
          <Save className="h-4 w-4 mr-2" />
          {isUploading ? "Téléchargement..." : "Sauvegarder"}
        </Button>
      </DialogFooter>
    </div>
  );
};

export default RoomEditForm;
