import React from "react";
import { DayData } from "../types";
import TimelineCell from "./TimelineCell";
import AvailableRoomsTooltip from "./AvailableRoomsTooltip";

interface TimelineRowProps {
  style: React.CSSProperties;
  dayData: DayData;
  setSelectedDay: (day: Date) => void;
  onBookingClick: (bookingId: string | null) => void;
  onQuickBookingClick: (date: Date, room: string) => void;
  highlightedBookingId: string | null;
  isToday: boolean;
}

const TimelineRow: React.FC<TimelineRowProps> = ({
  style,
  dayData,
  setSelectedDay,
  onBookingClick,
  onQuickBookingClick,
  highlightedBookingId,
  isToday,
}) => {
  return (
    <div style={style}>
      <div className="flex-shrink-0 w-14">
        <AvailableRoomsTooltip
          dayData={dayData}
          setSelectedDay={setSelectedDay}
          isToday={isToday}
        />
        {dayData.roomBookings.map((roomBooking) => (
          <TimelineCell
            key={roomBooking.id}
            roomBooking={roomBooking}
            date={dayData.date}
            onBookingClick={onBookingClick}
            onQuickBookingClick={onQuickBookingClick}
            highlightedBookingId={highlightedBookingId}
            isToday={isToday}
          />
        ))}
        {dayData.waitingListSlots.map((booking, index) => (
          <TimelineCell
            key={`waiting-list-${index}`}
            roomBooking={{
              id: -index,
              name: "Liste d'attente",
              booking,
            }}
            date={dayData.date}
            onBookingClick={onBookingClick}
            onQuickBookingClick={onQuickBookingClick}
            highlightedBookingId={highlightedBookingId}
            isWaitingList
            isToday={isToday}
          />
        ))}
      </div>
    </div>
  );
};

export default TimelineRow;
