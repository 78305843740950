import React, { useState, useMemo, useRef, useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import BookingCard from "./BookingCard";
import BookingListHeader from "./BookingListHeader";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import type { Booking } from "../types";

interface BookingListProps {
  bookings: Booking[];
  onEdit: (booking: Booking) => void;
  onDelete: (bookingId: string) => void;
  highlightedBookingId: string | null;
  onBookingClick: (bookingId: string) => void;
}

const BookingList: React.FC<BookingListProps> = ({
  bookings,
  onEdit,
  onDelete,
  highlightedBookingId,
  onBookingClick,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bookingToDelete, setBookingToDelete] = useState<string | null>(null);
  const [sortOption, setSortOption] = useState<string>("startDate");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const listRef = useRef<HTMLDivElement>(null);

  const handleDeleteClick = (bookingId: string) => {
    setBookingToDelete(bookingId);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (bookingToDelete) {
      onDelete(bookingToDelete);
    }
    setDeleteDialogOpen(false);
    setBookingToDelete(null);
  };

  const sortedAndFilteredBookings = useMemo(() => {
    let result = [...bookings];

    if (searchTerm) {
      const lowercaseTerm = searchTerm.toLowerCase();
      result = result.filter(
        (booking) =>
          booking.guest_name.toLowerCase().includes(lowercaseTerm) ||
          (booking.comment &&
            booking.comment.toLowerCase().includes(lowercaseTerm)),
      );
    }

    switch (sortOption) {
      case "startDate":
        result.sort(
          (a, b) =>
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
        );
        break;
      case "created":
        result.sort((a, b) => {
          if ("created_at" in a && "created_at" in b) {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          }
          return 0;
        });
        break;
      case "updated":
        result.sort((a, b) => {
          if ("updated_at" in a && "updated_at" in b) {
            return (
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
            );
          }
          return 0;
        });
        break;
    }

    return result;
  }, [bookings, sortOption, searchTerm]);

  useEffect(() => {
    if (highlightedBookingId && listRef.current) {
      const highlightedElement = listRef.current.querySelector(
        `[data-booking-id="${highlightedBookingId}"]`,
      );
      if (highlightedElement) {
        const listRect = listRef.current.getBoundingClientRect();
        const elementRect = highlightedElement.getBoundingClientRect();
        const scrollTop = listRef.current.scrollTop;
        const elementTop = elementRect.top - listRect.top + scrollTop;

        listRef.current.scrollTo({
          top: elementTop - 40,
          behavior: "smooth",
        });
      }
    }
  }, [highlightedBookingId]);

  return (
    <div className="mt-8 bg-gray-100 p-4 rounded-xl">
      <h2 className="text-xl font-semibold mb-4">Liste des demandes</h2>
      <BookingListHeader
        sortOption={sortOption}
        setSortOption={setSortOption}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className="space-y-4">
        {sortedAndFilteredBookings.length === 0 ? (
          <Card>
            <CardContent className="p-4">
              <p className="text-sm text-center text-gray-500">
                Aucune demande trouvée. Ajustez vos critères de recherche ou
                utilisez le bouton "Demander une chambre" pour faire une
                nouvelle demande !
              </p>
            </CardContent>
          </Card>
        ) : (
          <div
            ref={listRef}
            className="overflow-y-auto"
            style={{ maxHeight: "500px" }}
          >
            {sortedAndFilteredBookings.map((booking) => (
              <BookingCard
                key={booking.id}
                booking={booking}
                onEdit={onEdit}
                onDelete={handleDeleteClick}
                highlightedBookingId={highlightedBookingId}
                onBookingClick={onBookingClick}
              />
            ))}
          </div>
        )}
      </div>
      <DeleteConfirmDialog
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default BookingList;
